import { useEffect, useState } from "react"
import { Link } from "gatsby"
import { MenuIcon, SearchIcon, XIcon } from "@heroicons/react/outline"
import { twMerge } from "tailwind-merge"
import NavItem from "./NavItem"
import menuData from "src/data/menulinks.json"

const { menuLinks } = menuData

const defaultNavStyle = "absolute sm:bg-transparent shadow-none"
const floatingNavStyle =
  "fixed top-0 left-0 bg-noir sm:bg-noir shadow-none sm:shadow-md [&_.floating-logo]:opacity-100"

const menuItemStyle =
  "whitespace-nowrap mb-2 block py-1 text-[22px] text-blanc hover:text-jaune sm:text-sm 2xl:py-0"

export default function Header({ handleSearch, forceFocus }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const [expandedMenu, setExpandedMenu] = useState(0)
  const [navbarClass, setNavbarClass] = useState(defaultNavStyle)
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "initial"
    }
  }, [isExpanded])

  useEffect(() => {
    const handleResize = () => {
      setExpandedMenu(0)
      toggleExpansion(false)
    }

    const handleScroll = () => {
      if (window === undefined) return

      const windowHeight = window.scrollY

      setNavbarClass(
        windowHeight >
          31 * parseFloat(getComputedStyle(document.documentElement).fontSize)
          ? floatingNavStyle
          : defaultNavStyle
      )
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <nav
      className={twMerge(
        "z-40 w-full overflow-auto px-4 py-0 transition-all duration-300 sm:px-4 2xl:overflow-visible 2xl:hover:bg-noir",
        isExpanded ? "!bg-noir" : "sm:bg-transparent",
        navbarClass,
        forceFocus && "!bg-noir"
      )}
    >
      <div
        className={`${
          isExpanded ? "h-screen" : "h-16"
        }  mx-auto flex flex-wrap items-center justify-between 2xl:flex-nowrap`}
      >
        <Link
          to="/"
          className={twMerge(
            "floating-logo flex h-auto items-center text-jaune",
            isExpanded ? "opacity-100" : "opacity-0"
          )}
        >
          SEEING SOLITARY
        </Link>
        <div className="3xl:hidden flex 2xl:order-2 2xl:hidden">
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center rounded-lg p-2 text-blanc focus:outline-none 2xl:hidden"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {isExpanded ? (
              <XIcon className="block h-6 w-6" aria-hidden="true" />
            ) : (
              <MenuIcon className="block h-6 w-6" aria-hidden="true" />
            )}
          </button>
        </div>
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } 2xl:flex-shrink-1 2xl:flex-grow-1 h-full w-full flex-shrink-0 flex-grow-0 basis-full items-center justify-between 2xl:order-1 2xl:flex 2xl:w-auto 2xl:basis-auto`}
          id="navbar-sticky"
          style={{ height: isExpanded ? "calc(100% - 64px)" : "auto" }}
        >
          <ul className="mt-6 flex w-full flex-col items-start rounded-lg md:m-0 2xl:mt-0 2xl:w-auto 2xl:flex-row 2xl:items-center 2xl:font-medium">
            <NavItem
              className={"static"}
              menuClassName={"2xl:w-full"}
              label={menuLinks[0].name}
              onClick={() => setExpandedMenu((x) => (x !== 2 ? 2 : 0))}
              expanded={expandedMenu === 2}
              onFocus={() => {
                setIsMenuClicked(false)
              }}
              isMenuClicked={isMenuClicked}
            >
              <div className="mx-auto mt-3 h-full w-full max-w-screen-xl flex-row sm:flex">
                <div className="mb-4 flex w-[16rem] flex-col gap-y-2 sm:mb-0">
                  <Link
                    to="/state-and-federal"
                    className="block py-1 text-[22px] text-blanc hover:text-jaune sm:text-sm 2xl:py-0"
                    onClick={() => {
                      toggleExpansion(false)
                      setIsMenuClicked(true)
                    }}
                  >
                    State & Federal Overview
                  </Link>
                </div>
                <div className="flex flex-1 flex-col">
                  <div className="text-13 min-h-0 w-full columns-2 overflow-hidden transition-all duration-300 ease-in-out 2xl:columns-5 2xl:text-base">
                    {menuLinks[0].subMenu.map((menuLink) => (
                      <Link
                        to={menuLink.link}
                        className="block py-1 text-[18px] text-blanc hover:text-jaune sm:text-sm 2xl:py-1"
                        key={menuLink.name}
                        onClick={() => {
                          toggleExpansion(false)
                          setIsMenuClicked(true)
                        }}
                      >
                        {menuLink.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </NavItem>
            <NavItem
              label={menuLinks[1].name}
              onClick={() => setExpandedMenu((x) => (x !== 1 ? 1 : 0))}
              expanded={expandedMenu === 1}
              onFocus={() => {
                setIsMenuClicked(false)
              }}
              isMenuClicked={isMenuClicked}
            >
              {menuLinks[1].subMenu.map((menuLink) => (
                <Link
                  to={menuLink.link}
                  className={menuItemStyle}
                  key={menuLink.name}
                  onClick={() => {
                    toggleExpansion(false)
                    setIsMenuClicked(true)
                  }}
                >
                  {menuLink.name}
                </Link>
              ))}
            </NavItem>
            <NavItem
              label={menuLinks[2].name}
              onClick={() => setExpandedMenu((x) => (x !== 3 ? 3 : 0))}
              expanded={expandedMenu === 3}
              onFocus={() => {
                setIsMenuClicked(false)
              }}
              isMenuClicked={isMenuClicked}
            >
              {menuLinks[2].subMenu.map((menuLink) => (
                <Link
                  to={menuLink.link}
                  className={menuItemStyle}
                  key={menuLink.name}
                  onClick={() => {
                    toggleExpansion(false)
                    setIsMenuClicked(true)
                  }}
                >
                  {menuLink.name}
                </Link>
              ))}
            </NavItem>
            <NavItem
              className={""}
              label={menuLinks[3].name}
              onClick={() => setExpandedMenu((x) => (x !== 4 ? 4 : 0))}
              expanded={expandedMenu === 4}
              onFocus={() => {
                setIsMenuClicked(false)
              }}
              isMenuClicked={isMenuClicked}
            >
              {menuLinks[3].subMenu.map((menuLink) => (
                <Link
                  to={menuLink.link}
                  className={twMerge(menuItemStyle, "whitespace-normal")}
                  key={menuLink.name}
                  onClick={() => {
                    toggleExpansion(false)
                    setIsMenuClicked(true)
                  }}
                >
                  {menuLink.name}
                </Link>
              ))}
            </NavItem>
            <NavItem
              label={menuLinks[4].name}
              onClick={() => setExpandedMenu((x) => (x !== 5 ? 5 : 0))}
              expanded={expandedMenu === 5}
              onFocus={() => {
                setIsMenuClicked(false)
              }}
              isMenuClicked={isMenuClicked}
            >
              {menuLinks[4].subMenu.map((menuLink) => (
                <Link
                  to={menuLink.link}
                  className="mb-2 block py-1 text-[22px] text-blanc hover:text-jaune sm:text-sm 2xl:py-0"
                  key={menuLink.name}
                  onClick={() => {
                    toggleExpansion(false)
                    setIsMenuClicked(true)
                  }}
                >
                  {menuLink.name}
                </Link>
              ))}
            </NavItem>
            <li className="w-full py-1 sm:w-auto sm:py-2">
              <button
                className={twMerge(
                  `flex w-full items-center justify-between gap-2 rounded-full px-3 py-2 text-[28px] font-medium leading-9 text-blanc transition-all duration-300 hover:bg-jaune hover:text-noir sm:text-sm `,
                  forceFocus && "bg-jaune text-noir"
                )}
                onClick={handleSearch}
              >
                Search <SearchIcon className="h-5 w-5 flex-shrink-0" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
