import React from "react"

export const LeftArrowIcon = ({ iconColor, ...rest }) => (
  <svg
    width="8"
    height="16"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M-2.84028e-05 7.99894C-0.000169738 7.85792 0.0243414 7.71826 0.0720957 7.58799C0.11985 7.45772 0.189906 7.3394 0.278238 7.23983L5.97652 0.797593C6.15515 0.599268 6.39583 0.488719 6.64619 0.490001C6.89655 0.491283 7.13633 0.604291 7.31336 0.804437C7.4904 1.00458 7.59036 1.27567 7.59149 1.55872C7.59262 1.84176 7.49484 2.11386 7.31942 2.31581L2.29264 7.99894L7.31948 13.6821C7.49571 13.8838 7.59417 14.1562 7.59338 14.4398C7.59258 14.7234 7.49259 14.9951 7.31523 15.1956C7.13788 15.3961 6.89756 15.5091 6.64674 15.51C6.39593 15.5109 6.15499 15.3995 5.97652 15.2003L0.278238 8.75805C0.189906 8.65849 0.11985 8.54017 0.0720957 8.40989C0.0243414 8.27962 -0.000169738 8.13996 -2.84028e-05 7.99894V7.99894Z"
      fill={iconColor || "white"}
    />
  </svg>
)
