import React from "react"

export const RightArrowIcon = ({ iconColor, ...rest }) => (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.1868 8.00106C8.18694 8.14208 8.16243 8.28174 8.11467 8.41201C8.06692 8.54228 7.99686 8.6606 7.90853 8.76017L2.21024 15.2024C2.03162 15.4007 1.79094 15.5113 1.54058 15.51C1.29022 15.5087 1.05044 15.3957 0.873403 15.1956C0.69637 14.9954 0.596413 14.7243 0.595279 14.4413C0.594145 14.1582 0.691927 13.8861 0.867349 13.6842L5.89413 8.00106L0.867292 2.31793C0.691061 2.11616 0.592594 1.84376 0.593389 1.5602C0.594183 1.27664 0.694175 1.00494 0.871533 0.80444C1.04889 0.603935 1.28921 0.490901 1.54003 0.490015C1.79084 0.489128 2.03178 0.600461 2.21024 0.799709L7.90853 7.24195C7.99686 7.34151 8.06692 7.45983 8.11467 7.59011C8.16243 7.72038 8.18694 7.86004 8.1868 8.00106Z"
      fill={iconColor || "white"}
    />
  </svg>
)
