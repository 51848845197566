import React from "react"

export const StandingManIcon = ({ iconColor, ...rest }) => (
  <svg
    width="61"
    height="190"
    viewBox="0 0 61 190"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M60.9444 101.376V37.4725C60.9444 33.0655 56.5807 32.9707 56.5807 32.9707H39.2693L41.4339 10.1559C41.4339 7.95168 41.163 5.63503 39.001 3.43083C36.8364 1.22791 34.9426 0 30.6163 0C26.2872 0 24.4225 1.24414 22.2607 3.44838C20.0961 5.65265 19.8558 7.94749 19.8558 10.1517L22.019 32.9706H4.70625C4.70625 32.9706 0.022616 31.9645 0.022616 37.4724V101.376C0.022616 101.376 -0.338585 108.261 1.82601 110.465C5.06759 113.77 8.41953 115.389 8.41953 115.389L14.4446 190H46.8445L52.7938 115.389C52.7938 115.389 56.0567 113.89 59.3024 110.585C61.4656 108.381 60.945 101.375 60.945 101.375L60.9444 101.376Z"
      fill={iconColor || "#FFDE26"}
    />
  </svg>
)
