import { useState } from "react"
import ScrollToTop from "./ScrollToTop"
import Footer from "./Footer/Footer"
import Header from "./Header/Header"
import Search from "./Header/Search"

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="flex flex-col bg-noir">
      <ScrollToTop />
      <Header handleSearch={() => setIsOpen((x) => !x)} forceFocus={isOpen} />
      <Search isOpen={isOpen} setIsOpen={setIsOpen} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
