exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-additional-resources-js": () => import("./../../../src/pages/additional-resources.js" /* webpackChunkName: "component---src-pages-additional-resources-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-and-reports-js": () => import("./../../../src/pages/data-and-reports.js" /* webpackChunkName: "component---src-pages-data-and-reports-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-firsthand-accounts-js": () => import("./../../../src/pages/firsthand-accounts.js" /* webpackChunkName: "component---src-pages-firsthand-accounts-js" */),
  "component---src-pages-health-spotlight-js": () => import("./../../../src/pages/health-spotlight.js" /* webpackChunkName: "component---src-pages-health-spotlight-js" */),
  "component---src-pages-in-the-news-js": () => import("./../../../src/pages/in-the-news.js" /* webpackChunkName: "component---src-pages-in-the-news-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legislation-js": () => import("./../../../src/pages/legislation.js" /* webpackChunkName: "component---src-pages-legislation-js" */),
  "component---src-pages-methodology-js": () => import("./../../../src/pages/methodology.js" /* webpackChunkName: "component---src-pages-methodology-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-state-and-federal-policies-js": () => import("./../../../src/pages/state-and-federal-policies.js" /* webpackChunkName: "component---src-pages-state-and-federal-policies-js" */),
  "component---src-pages-what-is-solitary-js": () => import("./../../../src/pages/what-is-solitary.js" /* webpackChunkName: "component---src-pages-what-is-solitary-js" */),
  "component---src-templates-jurisdiction-index-js": () => import("./../../../src/templates/Jurisdiction/index.js" /* webpackChunkName: "component---src-templates-jurisdiction-index-js" */),
  "component---src-templates-state-federal-overview-index-js": () => import("./../../../src/templates/StateFederalOverview/index.js" /* webpackChunkName: "component---src-templates-state-federal-overview-index-js" */)
}

