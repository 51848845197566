export default function ExternalLink({ children, className = "", ...props }) {
  return (
    <a
      className={`text-bleu2 visited:text-violette hover:underline ${className}`}
      target={"_blank"}
      rel="noreferrer noopener"
      {...props}
    >
      {children}
    </a>
  )
}
