import { Link } from "gatsby"
import ExternalLink from "../../Section/ExternalLink"
import siteLogo from "src/assets/images/site-logo.svg"

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "View the Data by State", href: "/states", current: false },
  { name: "Resources", href: "/resources", current: false },
  { name: "Reports", href: "/reports", current: false },
  { name: "Methodology", href: "/methodology", current: false },
  { name: "FAQ", href: "/faq", current: false },
  { name: "About", href: "/about", current: false },
]

export default function Footer() {
  return (
    <footer className="bg-noir text-blanc">
      <div className="mx-auto flex max-w-7xl flex-col items-center px-16 pt-16 pb-16 text-center sm:px-24 sm:pt-24 sm:pb-24 md:items-start md:text-left lg:flex lg:justify-between">
        <img
          src={siteLogo}
          alt="Seeing Solitary"
          className="w-[180px] sm:w-[240px] md:w-[360px]"
        />
        <div className="mt-12 font-mono text-sm uppercase text-jaune">
          A project of the Liman Center
          <br />
          at Yale Law School
        </div>
        <ul className="mt-32 hidden">
          {navigation.map((item) => (
            <li
              className="my-2"
              key={`footer-link-${item.name.split(" ").pop()}`}
            >
              <Link to={item.href}>{item.name}</Link>
            </li>
          ))}
        </ul>
        <small className="mt-8 text-xs">
          &#169; 2023 Yale University ·&nbsp;
          <ExternalLink
            className="!text-blanc !visited:text-blanc underline"
            href="https://usability.yale.edu/web-accessibility/accessibility-yale"
          >
            Accessibility at Yale
          </ExternalLink>{" "}
          ·{" "}
          <ExternalLink
            className="!text-blanc !visited:text-blanc underline"
            href="http://www.yale.edu/privacy-policy"
          >
            Privacy policy
          </ExternalLink>{" "}
        </small>
        <small className="mt-3 text-xs">
          Site by{" "}
          <ExternalLink
            href="https://hyperobjekt.com"
            target="_blank"
            rel="noreferrer noopener"
            className="!text-blanc !visited:text-blanc underline"
          >
            Hyperobjekt
          </ExternalLink>
        </small>
      </div>
    </footer>
  )
}
