import "./src/styles/index.css"
import "./src/styles/react-slick.css"
import "./src/styles/pagefind.css"
import "./src/polyfills"
import "react-loading-skeleton/dist/skeleton.css"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Layout from "./src/components/Layout"

const queryClient = new QueryClient()

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  )
}
