import { useEffect, useState } from "react"
import { XIcon } from "@heroicons/react/outline"
import { AnimatePresence, motion } from "framer-motion"
import { useQuery } from "@tanstack/react-query"
import Skeleton from "react-loading-skeleton"
import { sortBy } from "lodash"
import { Container } from "src/components/Container/"
import siteLogo from "src/assets/images/site-logo.svg"

const ANIMATION_DURATION = 0.25

const Search = ({ isOpen, setIsOpen }) => {
  const [searchTerm, setSearchTerm] = useState("")

  const handleSearch = async (term) => {
    const pagefind = await import(
      // eslint-disable-next-line import/no-unresolved
      /* webpackIgnore: true */ "/pagefind/pagefind.js"
    )
    const search = await pagefind.debouncedSearch(term)

    if (!term) return null

    if (!search) return []
    const searchResults = await Promise.all(search.results.map((r) => r.data()))

    return sortBy(searchResults, ({ url }) =>
      url === "/state-and-federal/" ? 0 : 1
    )
  }

  const {
    isFetching,
    isFetched,
    error,
    data: results = [],
  } = useQuery({
    queryKey: [searchTerm],
    queryFn: () => handleSearch(searchTerm),
    enabled: !!searchTerm,
  })

  useEffect(() => {
    if (!isOpen && results && searchTerm) {
      setSearchTerm("")
    }
  }, [isOpen, results, searchTerm])

  const showResults = error || (isOpen && isFetched)

  useEffect(() => {
    setTimeout(
      () => {
        document.body.style.overflow = showResults ? "hidden" : "initial"
      },
      showResults ? ANIMATION_DURATION * 1000 : 0
    )
  }, [showResults])

  return (
    <div className="fixed left-0 top-16 z-40">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="h-16 w-screen bg-jaune"
            initial={{ y: -64, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -64, opacity: 0 }}
            transition={{ bounce: 0 }}
          >
            <Container className="flex h-full items-center justify-between">
              <input
                className="w-[272px] flex-1 bg-transparent text-[18px] leading-[36px] placeholder-noir outline-none"
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                value={searchTerm}
                placeholder="Search entire site ..."
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus // a11y is not a concern because this gets opened by a user event
              />
              <button
                className="flex items-center gap-2 p-4 text-xs"
                onClick={() => setIsOpen((x) => !x)}
              >
                Close <XIcon className="h-4 w-4 flex-shrink-0" />
              </button>
            </Container>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showResults && (
          <motion.div
            className="h-[calc(100vh-128px)] w-screen overflow-auto overscroll-contain bg-blanc md:p-16 md:pt-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: ANIMATION_DURATION, bounce: 0 }}
          >
            <Container className="flex flex-col">
              {isFetching ? (
                new Array(5).fill({}).map((x, i) => (
                  <div key={i} className="flex h-[224px] gap-16 py-4">
                    <Skeleton
                      className="h-[120px] min-w-[180px] max-w-[180px]"
                      style={{
                        lineHeight: 1.6,
                        borderRadius: 0,
                      }}
                    />
                    <div className="flex flex-1 flex-col gap-4">
                      <Skeleton
                        containerClassName="text-[18px] leading-[24px]"
                        height={18}
                        style={{
                          lineHeight: 1.6,
                          borderRadius: 0,
                        }}
                      />
                      <Skeleton
                        containerClassName="h-[132px] text-[16px] leading-[22px]"
                        height={16}
                        count={2.75}
                        style={{
                          lineHeight: 1.6,
                          borderRadius: 0,
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : results.length === 0 ? (
                <div className="italic">No results found</div>
              ) : (
                results.map(({ url, excerpt, meta: { title, image } }, i) => (
                  <a
                    key={i}
                    href={url}
                    className="flex flex-col items-start gap-4 p-4 hover:bg-gris md:flex-row md:items-center md:gap-16"
                  >
                    <img
                      src={image || siteLogo}
                      alt=""
                      className="w-full max-w-[180px] self-start bg-noir"
                    />
                    <div className="flex flex-1 flex-col gap-4">
                      <div
                        className="text-[18px] leading-[24px]"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                      <p
                        className="[word-break: break-word] line-clamp-6 text-[16px] leading-[22px] [overflow-wrap:anywhere]"
                        dangerouslySetInnerHTML={{ __html: excerpt }}
                      />
                    </div>
                  </a>
                ))
              )}
            </Container>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Search
