import PropTypes from "prop-types"
import { forwardRef } from "react"
import { twMerge } from "tailwind-merge"

const Container = ({ children, className }, ref) => {
  return (
    <div
      ref={ref}
      className={twMerge(
        "mx-auto w-full px-4 md:w-[624px] md:px-0 lg:w-[832px]",
        className
      )}
    >
      {children}
    </div>
  )
}

export default forwardRef(Container)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
