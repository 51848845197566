import { useRef } from "react"
import { twMerge } from "tailwind-merge"
import { RightArrowIcon } from "src/assets/svg"

const NavItem = ({
  className,
  menuClassName,
  label,
  expanded,
  onClick,
  isMenuClicked,
  onFocus,
  children,
  ...props
}) => {
  const ref = useRef()

  return (
    <li
      className={twMerge(
        "group relative w-full py-2 text-blanc focus-within:text-jaune hover:text-jaune sm:mb-0 sm:py-3 2xl:w-auto 2xl:max-w-[400px]",
        className
      )}
      {...props}
    >
      <button
        className="hover:bg-gray-700 flex w-full items-center gap-3 rounded-md px-3 py-2 text-left text-[28px] font-medium leading-9 sm:w-auto sm:text-sm"
        onClick={onClick}
        onMouseOver={onFocus}
        onFocus={onFocus}
      >
        {label}{" "}
        {children && (
          <RightArrowIcon className="ml-auto mr-2 flex-shrink-0 rotate-90" />
        )}
      </button>
      {!isMenuClicked && children && (
        <div
          ref={ref}
          className={twMerge(
            `${
              expanded
                ? `mt-4 flex h-[${ref.current?.scrollHeight}px]`
                : `h-0 2xl:flex`
            } left-0 top-full flex w-full flex-col flex-nowrap overflow-hidden bg-noir px-4 text-blanc transition-all duration-300 2xl:invisible 2xl:absolute 2xl:mt-0
            2xl:h-[initial] 2xl:w-auto 2xl:-translate-y-2 2xl:p-8 2xl:opacity-0 2xl:group-focus-within:visible 2xl:group-focus-within:translate-y-0 2xl:group-focus-within:opacity-100 2xl:group-hover:visible 2xl:group-hover:translate-y-0 2xl:group-hover:opacity-100`,
            menuClassName
          )}
        >
          {children}
        </div>
      )}
    </li>
  )
}

export default NavItem
